// check if a url is valid.
import Container from "typedi";
import ErrorHandlerService from "@/modules/common/services/error-handler.service";

export default function checkURLAndGo (href: string): void {
    const localOrigin = new URL(process.env.VUE_APP_REG_URL ?? 'http://localhost:8081/').origin
    const idpOrigin = new URL('https://prod.personifyauth.com/').origin
    const mcproOrigin = new URL('https://mcu.memberclicks.net/').origin
    const mcTradeOrigin = new URL('https://www.weblinkauth.com/').origin

    // make the allowList immutable to prevent it being modified.
    const allowList = Object.freeze([
        localOrigin,
        idpOrigin,
        mcproOrigin,
        mcTradeOrigin
    ])

    const url = new URL(href.charAt(0) === '/' ? location.origin + href : href)
    if (allowList.indexOf(url.origin) === -1) {
        Container.get(ErrorHandlerService).error('Unauthorized redirect: href is not an allowed url')
    } else {
        window.location.href = url.toString()
    }
}
